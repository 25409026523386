<template>
  <v-form ref="form_add_category" @submit.prevent="handleAdd">
    <v-container fluid>
      <v-row>
        <v-col class="text-h6">
          {{ $trans("add_category") }}
          <v-divider class="mt-2" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="name"
            hide-details="auto"
            :rules="[rules.required, rules.maxChars(50)]"
            :label="$trans('category_name')"
            outlined
          />
          <v-checkbox
            v-model="status"
            hide-details
            false-value="inactive"
            true-value="active"
            :label="$trans('category_active')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex justify-end">
          <v-btn
            color="light"
            text
            :disabled="isLoading"
            @click="$emit('close')"
          >
            {{ $trans("cancel") }}
          </v-btn>
          <v-btn :disabled="isLoading" color="primary" text type="submit">
            {{ $trans("add") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import {
  maxChars,
  required,
} from "@/lib/calendesk-js-library/forms/validators";
import { errorNotification } from "@/lib/calendesk-js-library/tools/notification";
import { pushEvent } from "@/lib/calendesk-js-library/tools/helpers";

export default {
  name: "AddGroupDialog",
  data() {
    return {
      isLoading: false,
      status: "active",
      name: null,
      rules: {
        required,
        maxChars,
      },
    };
  },
  methods: {
    ...mapActions({
      createCategory: "category/add",
      fetchStats: "dashboard/fetchStats",
    }),
    ...mapMutations({
      setCategory: "category/SET_CATEGORY_ID",
    }),
    handleAdd() {
      if (this.$refs.form_add_category.validate()) {
        this.add();
      } else {
        errorNotification("form_is_invalid", {}, false);
      }
    },
    async add() {
      this.isLoading = true;
      this.$emit("loading", true);
      try {
        pushEvent("createCategory");

        const { id } = await this.createCategory({
          name: this.name,
          status: this.status,
        });
        if (this.$route.name === "add_service") {
          this.setCategory(id);
        }
        await this.fetchStats();
      } catch (error) {
        errorNotification(null, error);
      } finally {
        this.isLoading = false;
        this.$emit("loading", false);
        this.$emit("close");
      }
    },
  },
};
</script>
